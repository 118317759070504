import { useEffect, useState } from "react";
import throttle from "lodash/throttle";

const useScroll = () => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = throttle(() => {
            setOffset(window.pageYOffset);
        }, 300);

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return { offset };
};

export default useScroll;
